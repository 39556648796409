var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mb-3",
      class: _vm.data.type,
      attrs: { id: "menu-" + _vm.data.type + "-type2" },
    },
    [
      _c("div", { staticClass: "sport__header d-flex align-center px-4" }, [
        _c(
          "div",
          { staticClass: "sport__header--img" },
          [
            _vm.data.type === "sport"
              ? _c("Icon", {
                  staticClass: "title--text",
                  attrs: {
                    data: require("@icon/block/game/type1/sport.svg"),
                    width: "18",
                    height: "18",
                  },
                })
              : _c("Icon", {
                  staticClass: "title--text",
                  attrs: {
                    data: require("@icon/block/game/type1/esport.svg"),
                    width: "18",
                    height: "18",
                  },
                }),
          ],
          1
        ),
        _c(
          "span",
          {
            staticClass:
              "sport__header--title font-weight-bold ml-2 title--text",
          },
          [_vm._v(" " + _vm._s(_vm.data.data.navbar[0].game_type) + " ")]
        ),
      ]),
      _c(
        "div",
        [
          _c(
            "swiper",
            {
              ref: "gameItem",
              staticClass: "mySwiper px-4 mt-2",
              attrs: { options: _vm.swiperOption },
            },
            _vm._l(_vm.gameItem, function (game) {
              return _c(
                "swiper-slide",
                { key: game._id, staticClass: "game__item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "game__item--card cursor-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.lauchGame(game.game_code)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "game-card rounded h-96px",
                          style: _vm.bg[_vm.theme],
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "game-img w-100 d-flex justify-center align-center rounded overflow-hidden",
                              class:
                                game.status === "0" ? "maintenanceBox" : "",
                              style: _vm.gradientBg,
                            },
                            [
                              game.status == "0" ? _c("maintenance") : _vm._e(),
                              _c("v-img", {
                                staticClass: "rounded",
                                attrs: {
                                  src: _vm.baseImgUrl + game.image_h5,
                                  height: "60",
                                  width: "50",
                                  position: "center right",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-100 game-name text-center subtitle-2 title--text rounded-b",
                            },
                            [_vm._v(" " + _vm._s(game.title) + " ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }