<template>
  <!-- SPORT type2 -->
  <div
    :id="'menu-' + data.type + '-type2'"
    class="mb-3"
    :class="data.type"
  >
    <!-- header -->
    <div class="sport__header d-flex align-center px-4">
      <div class="sport__header--img">
        <!-- SPORT ICON -->
        <Icon
          v-if="data.type === 'sport'"
          data="@icon/block/game/type1/sport.svg"
          width="18"
          height="18"
          class="title--text"
        />
        <!-- ESPORT ICON -->
        <Icon
          v-else
          data="@icon/block/game/type1/esport.svg"
          width="18"
          height="18"
          class="title--text"
        />
      </div>
      <span class="sport__header--title font-weight-bold ml-2 title--text">
        {{ data.data.navbar[0].game_type }}
      </span>
    </div>

    <!-- items -->
    <div>
      <swiper
        ref="gameItem"
        :options="swiperOption"
        class="mySwiper px-4 mt-2"
      >
        <swiper-slide
          v-for="game in gameItem"
          :key="game._id"
          class="game__item"
        >
          <div
            class="game__item--card cursor-pointer"
            @click="lauchGame(game.game_code)"
          >
            <div
              class="game-card rounded h-96px"
              :style="bg[theme]"
            >
              <div
                class="game-img w-100 d-flex justify-center align-center rounded overflow-hidden"
                :style="gradientBg"
                :class="game.status==='0'?'maintenanceBox':''"
              >
                <!-- 維修中 父層要加 :class="game.status==='0'?'maintenanceBox':''"-->
                <maintenance v-if="game.status=='0'" />
                <v-img
                  :src="baseImgUrl + game.image_h5"
                  height="60"
                  width="50"
                  class="rounded"
                  position="center right"
                />
              </div>

              <div class="w-100 game-name text-center subtitle-2 title--text rounded-b">
                {{ game.title }}
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import maintenance from '@/components/maintenance'
export default {
  components: { maintenance },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    gameUrl: '',
    swiperOption: {
      slidesPerView: 3.1,
      spaceBetween: 8,
      centeredSlides: true,
      centeredSlidesBounds: true,
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme', 'gradientBg']),

    gameItem() {
      return this.data.data.navbar[0].items
    },

    // item background
    bg() {
      return {
        light: { background: 'rgba(206, 206, 206, 0.12)' },
        dark: { background: 'rgba(0, 0, 0, 0.25)' },
      }
    },
  },

  methods: {
    ...mapActions([
      'get_game_url',
      'show_alert',
      'set_progress_status',
      'get_game_url_by_device',
    ]),
    /**
     * 啟動遊戲
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     */
    lauchGame(gameCode) {
      // 體育類 ninew 跳新頁面
      if (gameCode === 'ninew_lobby') {
        this.set_progress_status(true)
        this.getGameUrl(gameCode)
        return
      }
      this.$router.push({
        name: 'launchGame',
        query: {
          t: gameCode,
          redirectUrl: this.$route.path,
        },
      })
    },
    /**
     * 取得遊戲 url (成功)
     * @date 2021-07-02
     * @param {string} gameUrl 遊戲連結 url
     */
    getGameUrlSuccess(gameUrl, windowObj) {
      this.show_alert({ icon: 'done' })
      this.gameUrl = gameUrl.url
      this.set_progress_status(false)
      windowObj.location.href = gameUrl.url
    },
    /**
     * 取得遊戲 url (失敗)
     * @date 2021-07-02
     */
    getGameUrlFail() {
      this.set_progress_status(false)
      this.show_alert({ icon: 'fail' })
      this.$router.replace({ path: '/' })
    },
    /**
     * 取得遊戲 url
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     */
    async getGameUrl(gameCode) {
      const windowObj = window.open('', '_blank')
      const res = await this.get_game_url_by_device({ gamecode: gameCode, is_mobile: 1 })
      resStatus(res, this.getGameUrlSuccess, this.getGameUrlFail, windowObj)
    },
  },
}
</script>

<style lang="scss" scoped>
.sport__header--title {
	font-size: 18px;
}

.h-96px {
	height: 96px;
}

.game-card {
	position: relative;

	.game-name {
		overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 95%;
    margin: 0 auto;
		height: 41px;
		line-height: 41px;
	}
}

.game-img {

}
</style>
